import {React,useEffect} from 'react'

import Faq from '../../Faq/Faq'

import MuleSoftBanner from './mulecompo/MuleSoftBanner'
import MuleSoftBenifits from './mulecompo/MuleSoftBenifits'
import MuleSoftBanner2 from './mulecompo/MuleSoftBanner2'
import WhySaasverseMule from './mulecompo/WhySaasverseMule'
import MuleSoftReadyBanner from './mulecompo/MuleSoftReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function MuleSoft() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <MuleSoftBanner/>
    <MuleSoftBenifits/>
    <MuleSoftBanner2/>
    <WhySaasverseMule/>
    <CertificateSlider/>
    <Trustedby2/>
    <MuleSoftReadyBanner/>
    <Faq/>
      
    </>
  )
}
