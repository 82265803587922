import { useState, useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./HomeEntry.css";
import Home from "../Home"; // Import your Home component

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const HomeEntry = () => {
  const [isHomeVisible, setHomeVisible] = useState(false);

  useEffect(() => {
    // GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#maingt",
        start: "50% 50%", // Adjust this to control when the animation starts
        end: "150% 50%",
        scrub: 2,
        pin: true,
        onLeave: () => setHomeVisible(true), // Show Home component after scroll animation
      },
    });

    tl.to("#centergt", { height: "100vh" }, "a")
      .to("#topgt", { top: "-50%" }, "a")
      .to("#bottomgt", { bottom: "-50%" }, "a")
      .to("#top-h1gt", { top: "60%" }, "a")
      .to("#bottom-h1gt", { bottom: "-30%" }, "a")
      .to(".contentgt", { delay: -0.2, marginTop: "0%" });

    // Cleanup the ScrollTrigger when the component is unmounted
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  useEffect(() => {
    if (isHomeVisible) {
      // Ensure the page scrolls to the top
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100); // A slight delay may help ensure the scroll happens after rendering
    }
  }, [isHomeVisible]);

  return (
    <>
      {/* Loading Page Animation */}
      {!isHomeVisible && (
        <div className="entrygt">
          <div id="maingt">
            <div id="topgt">
              <h1 id="top-h1gt">SAASVERSE</h1>
            </div>
            <div id="centergt">
              <img src="/assets/image/aaa1.png" alt="loading-img" />
            </div>
            <div id="bottomgt">
              <h1 id="bottom-h1gt">SAASVERSE</h1>
            </div>
          </div>
        </div>
      )}

      {/* Home Component Visible After Scroll */}
      {isHomeVisible && <Home />}
    </>
  );
};

export default HomeEntry;
