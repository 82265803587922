import React from 'react';
import './ContactInfo.css'; // Import the CSS file
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';
const ContactInfo = () => {
  return (
    <div className="contact-info-container">
      <div className="contact-details">
        <h2>Get in Touch</h2>
        <p>
          <strong>Office Address:</strong><br />
          Saasverse Pvt Ltd<br />
          Office 301 and FC-1, Anchor Mall, Ajmer Rd, Madrampur, Ganpati Nagar, Jaipur, Rajasthan-302006
        </p>
        <p><strong>Phone:</strong></p>
      <p>  USA: +1 (617) 981-4454</p>
      <p>  INDIA: +91 9001622254</p>
        <p><strong>Email:</strong> contact@saasverse.in</p>
        <p>
          <strong>Business Hours:</strong><br />
          Monday - Friday: 9:00 AM - 6:00 PM<br />
          Saturday - Sunday: Closed
        </p>
      </div>

      <div className="connect-with-us" style={{backgroundColor:'black'}}>
        <h2>Connect With Us</h2>
        <p>Follow Us on Social Media:</p>
        <ul className="social-links">
          <li><a href="https://www.linkedin.com/company/saasverse-pvt-ltd/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-2x"></i></a></li>
          <li><a href="https://www.facebook.com/saasverse.in" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-2x"></i></a></li>
          <li><a href="https://www.instagram.com/saasverse.in/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-2x"></i></a></li>
         
              <Link to="https://x.com/SAASVERSEIndia" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-x-twitter fa-2x"></i> 
  </Link>
        </ul>
        <p style={{color:'white'}}>Stay updated with our latest news, updates, and job openings by following us on social media.</p>
      </div>
    </div>
  );
};

export default ContactInfo;
