import React, { useState, useEffect } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import './SalesforceExpertiseHome.css';

// Import images
import image1 from "./image/salesC.png";
import image2 from "./image/serviceC.png";
import image3 from "./image/marketingC.png";
import image4 from "./image/healthC.png";
import image5 from "./image/experienceC.png";
import image6 from "./image/data-new.png";
import image7 from "./image/financeC.png";

export default function SalesforceExpertiseHome() {
  const [focusElement, setFocusElement] = useState(0);
  const [carouselRadius, setCarouselRadius] = useState(200);  // Default radius
  const [centralImageRadius, setCentralImageRadius] = useState(50); // Default radius
  const [peripheralImageRadius, setPeripheralImageRadius] = useState(50); // Default radius

  const images = [image1, image2, image3, image4, image5, image6, image7];

  const info = [
    {
      title: "Expertise in Sales Cloud Solutions",
      description:
        "At Saasverse Pvt Limited, we specialize in leveraging cutting-edge Sales Cloud solutions to transform and optimize your sales processes. Our tailored approach ensures that your sales operations are finely tuned for optimal efficiency and growth.",
      link: "/expertise/salesforce-sales-cloud/",
    },
    {
      title: "Expertise in Service Cloud Solutions",
      description:
        "Saasverse Pvt Limited specializes in transforming service operations through advanced Service Cloud solutions. We empower businesses to deliver exceptional customer service and optimize operational efficiency.",
      link: "/expertise/salesforce-service-cloud/",
    },
    {
      title: "Expertise in Marketing Cloud Solutions",
      description:
        "Struggling to cut through the digital noise and engage your audience? Tired of campaigns that fizzle out You’re not alone. But it doesn’t have to be this way.",
      link: "/expertise/salesforce-marketing-cloud/",
    },
    {
      title: "Expertise in Health Cloud Solutions",
      description:
        "In today’s healthcare landscape, delivering personalized care is no longer a luxury; it’s essential. Managing complex patient data, fostering collaboration, and ensuring a seamless patient experience can be a challenge.",
      link: "/expertise/health-cloud-services/",
    },
    {
      title: "Expertise in Experience Cloud Solutions",
      description:
        "In today’s digital age, generic one-size-fits-all experiences simply don’t cut it. Customers crave connection and personalization. A disjointed website visit or a bland mobile app interaction can leave them feeling forgotten and quickly turn them away.",
      link: "/expertise/experience-cloud/",
    },
    {
      title: "Expertise in Data Cloud Solutions",
      description:
        "In today’s data-driven world, customer insights are the gold mine of any successful business. However, managing scattered customer information across various systems can be a frustrating roadblock. Here’s where Salesforce Data Cloud steps in – a game-changing platform designed to unify your customer data and unlock its true potential.",
      link: "#",
    },
    {
      title: "Expertise in Financial Cloud Solutions",
      description:
        "In today’s competitive financial landscape, building strong customer relationships is paramount. However, managing complex financial interactions and fostering personalized experiences can be a challenge.",
      link: "/expertise/financial-cloud-implementation-services/",
    },
  ];

  useEffect(() => {
    // Check screen width on initial render and on window resize
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCarouselRadius(100);  // Smaller radius on mobile
        setCentralImageRadius(30); // Smaller central image radius on mobile
        setPeripheralImageRadius(30); // Smaller peripheral image radius on mobile
      } else {
        setCarouselRadius(200);  // Default radius for larger screens
        setCentralImageRadius(60); // Default central image radius
        setPeripheralImageRadius(50); // Default peripheral image radius
      }
    };

    // Run on mount and whenever window size changes
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div className="expertise-home-containernew">
      <div className="carousel-containernew">
        <FancyCarousel
          images={images}
          setFocusElement={setFocusElement}
          carouselRadius={carouselRadius} // Dynamically adjusted carousel radius
          peripheralImageRadius={peripheralImageRadius} // Dynamically adjusted peripheral image radius
          centralImageRadius={centralImageRadius} // Dynamically adjusted central image radius
          focusElementStyling={{ border: "2px solid transparent" }}
          autoRotateTime={3}
          borderWidth={0}
          borderHexColor={"#1c364f"}
        />
      </div>
      <div className="info-containernew">
        <h2>{info[focusElement].title}</h2>
        <p>{info[focusElement].description}</p>
        <a href={info[focusElement].link} className="info-read-more-buttonnew">
          Read More
        </a>
      </div>
    </div>
  );
} 