import React from 'react';
import './Footer.css'; // Ensure the path to your CSS file is correct
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';
import GoToTopButton from '../Home/Component/GoToTopButton';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-row">
          <div className="footer-column">
            <h5>Our Core Services</h5>
            <ul className="list-unstyled footer-list">
             <li> <Link to='/salesforce-appexchange-development-company' onClick={scrollToTop}>Salesforce AppExchange</Link></li>
            <li> <Link to='/salesforce-isv-development-company' onClick={scrollToTop}> Salesforce ISV Development </Link></li>
               <li><Link to='/salesforce-managed-services' onClick={scrollToTop}>Salesforce Managed Services</Link></li>
             <li>  <Link to='/salesforce-consulting-company' onClick={scrollToTop}>Salesforce Consulting Services</Link></li>
              <li> <Link to='/salesforce-integration-services' onClick={scrollToTop}>Salesforce Integration Services</Link> </li>
             <li> <Link to='/salesforce-custom-development-services' onClick={scrollToTop}> Salesforce Custom development </Link></li>
              <li><Link to='/salesforce-security-review' onClick={scrollToTop}>Salesforce Security Review</Link></li>
              {/* <li>Salesforce Training</li> */}
             
            </ul>
          </div>
          <div className="footer-column">
            <h5>Our Expertise</h5>
            <ul className="list-unstyled footer-list" >
           <li> <Link to='/expertise/salesforce-sales-cloud/' onClick={scrollToTop}>Sales Cloud</Link></li>
              <li> <Link to='/expertise/salesforce-service-cloud/' onClick={scrollToTop}>Service Cloud</Link></li>
              <li> <Link to='/expertise/salesforce-marketing-cloud/' onClick={scrollToTop}>Marketing Cloud</Link></li>
              <li> <Link to='/expertise/health-cloud-services/' onClick={scrollToTop}>Health Cloud</Link></li>
              <li> <Link to='/expertise/mulesoft-development/' onClick={scrollToTop}>Mulesoft Development </Link></li>
               <li><Link to='/expertise/salesforce-cpq/' onClick={scrollToTop}>CPQ Implementation</Link></li>
               <li><Link to='/expertise/nonprofit-cloud/' onClick={scrollToTop}>Nonprofit Cloud</Link></li>
               {/* <li>Salesforce 360</li> */}
               {/* <li>Salesforce Einstein</li> */}
            </ul>
          </div>
          <div className="footer-column">
            <h5>Company</h5>
            <ul className="list-unstyled footer-list" >
               <li><Link to='/about-us/' onClick={scrollToTop}>About Us</Link></li>
              <li> <Link to='/career/' onClick={scrollToTop}>Career</Link></li>
               {/* <a href='/'><li>Blogs</li></a> */}
               <li><Link to='/contact-us/' onClick={scrollToTop}>Contact</Link></li>
               <li><Link to='/disclaimer' onClick={scrollToTop}>Disclaimer</Link></li>
                <li><Link to='/privacy' onClick={scrollToTop}>Privacy Policy</Link></li>
               <li><Link to='/terms' onClick={scrollToTop}>Terms & Condition</Link></li>
              
            </ul>
        
          </div>
          <div className="footer-column">
            <img src="/assets/images/saasverse_whitelogo.png" alt="Company Logo" className="footer-logo" style={{marginTop:'-15px'}} /> {/* Path to logo */}
            <address className="footer-address">
           <div className='add' >
            <p style={{fontSize:'25px'}} >United States</p> 
            <p  style={{fontSize:'20px'}} > <a href='tel:+16179814454' style={{textDecoration:'none',color:'white'}}>+1 (617) 981-4454</a></p>
            <p style={{fontSize:'25px'}}>INDIA</p>
            <p style={{fontSize:'20px'}}> <a href='tel:+91-8949422940' style={{textDecoration:'none',color:'white'}}>+91-9001622254</a></p>

           </div>
              <div style={{marginTop:'10px'}}> Email:<a href="mailto:contact@sasverse.in"> contact@saasverse.in</a></div>
            </address>
            <div className="footer-social">
              <Link to="https://www.linkedin.com/company/saasverse-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin fa-2x"></i>
              </Link>
              <Link to="https://www.instagram.com/saasverse.in/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-2x"></i>
              </Link>
              <Link to="https://www.facebook.com/saasverse.in" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook fa-2x"></i>
              </Link>
              <Link to="https://x.com/SAASVERSEIndia" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-x-twitter fa-2x"></i> {/* Replace with correct icon class for X logo */}
  </Link>
            </div>
         
          
          </div>
          
        </div>
        {/* <hr className="footer-divider" /> */}
     


        <hr className="footer-divider" />
        <div className="responsive-footer">
  <div className="responsive-footer-column" style={{width:'100%',margin:'auto'}}>
    <ul className="responsive-footer-list">
      <li><img src="/assets/images/footer/c6.png" alt="Icon 1" /></li>
      <li><img src="/assets/images/footer/c1.webp" alt="Icon 2" /></li>
      <li><img src="/assets/images/footer/c5.webp" alt="Icon 3" /></li>
      <li><img src="/assets/images/footer/c4.webp" alt="Icon 4" /></li>
      <li><img src="/assets/images/footer/c3.webp" alt="Icon 5" /></li>
      <li><img src="/assets/images/footer/c2.webp" alt="Icon 6" /></li>
      <li><img src="/assets/images/footer/2.png" alt="Icon 7" /></li>
      <li><img src="/assets/images/footer/4.png" alt="Icon 8" /></li>
      <li><img src="/assets/images/footer/c8.png" alt="Icon 9" /></li>
      <li className="last-logo"><a href='https://clutch.co/profile/saasverse'  target="_blank"><img src="/assets/images/footer/1.png" alt="Icon 9" /></a></li>
    </ul>
  </div>
</div>









        <hr className="footer-divider" />
        <div className="footer-values-row">
          <div className="footer-values-column">
            <ul className="list-unstyled footer-values-list">
            <h3 >Our Values</h3>
              <li>
                <img src="/assets/images/footer/value1.svg"  alt='value1'/>
                Give Back To The Community
              </li>
              <li>
                <img src="/assets/images/footer/value2.svg" alt="Value Icon 2" />
                Foster a Culture of Caring
              </li>
              <li>
                <img src="/assets/images/footer/value3.svg" alt="Value Icon 3" />
                Deliver Seamless Experiences & Best in-Class Solutions
              </li>
              <li>
                <img src="/assets/images/footer/value4.svg" alt="Value Icon 4" />
                Embrace Change & Strive for Growth
              </li>
            </ul>
          </div>
        </div>
        <hr className="footer-divider" />
        <div className="footer-copyright">
          &copy; {new Date().getFullYear()} Saasverse PVT LTD. All rights reserved.
          <a href="//www.dmca.com/Protection/Status.aspx?ID=fa3809f2-6191-4ab6-8421-37baa3719152" title="DMCA.com Protection Status" class="dmca-badge" style={{marginLeft:'50px'}}> <img src ="https://images.dmca.com/Badges/dmca_protected_sml_120m.png?ID=fa3809f2-6191-4ab6-8421-37baa3719152"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
        </div>
      </div>
      <GoToTopButton/>
    </footer>
  );
}

export default Footer;
