import {React,useEffect} from 'react'


import Faq from '../../Faq/Faq'
import HealthCloudBanner from './healthcompo/HealthCloudBanner'
import HealthCloudBenifits from './healthcompo/HealthCloudBenifits'
import HealthCloudBanner2 from './healthcompo/HealthCloudBanner2'
import WhySaasverseHealth from './healthcompo/WhySaasverseHealth'
import HealthCloudReadyBanner from './healthcompo/HealthCloudReadyBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import Trustedby2 from '../../../Home/Component/TrustedBy2'

export default function HealthCloud() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <><HealthCloudBanner/>
    <HealthCloudBenifits/>
    <HealthCloudBanner2/>
    <WhySaasverseHealth/>
    <CertificateSlider/>
    <Trustedby2/>
    <HealthCloudReadyBanner/>
    <Faq/>
      
    </>
  )
}
