import React, { useRef, useEffect } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import './ScrollSection.css'

import 'react-fancy-circular-carousel/FancyCarousel.css';


function ScrollSection() {
  const sectionRef = useRef(null);
  const triggerRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const pin = gsap.fromTo(
      sectionRef.current,
      {
        translateX: 0,
      },
      {
        translateX: `-410vw`,
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: triggerRef.current,
          start: "top top",
          end: "2000 top",
          scrub: 0.6,
          pin: true,
          pinSpacing: true,
        },
      }
    );
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill()); // Clear all ScrollTriggers
      pin.kill(); // Clear the specific animation
    };
  }, []);




  return (
    <>
    <section className="scroll-section-outerks">
      {/* The section up act just as a wrapper. If the trigger (below) is the
      first jsx element in the component, you get an error on route change */}
<h1 className="heading-secondaryks">Platform Services</h1>
      {/* The div below act just as a trigger. As the doc suggests, the trigger and 
      the animation should alway be two separated refs */}
         
      <div ref={triggerRef}>
        <div ref={sectionRef} className="scroll-section-innerks">
          {/* <div className="scroll-sectionks">
          <div className="carouseltag">
      <div className="maintag">
        <FancyCarousel 
          images={images} 
          setFocusElement={setFocusElement}
          carouselRadius={200}
          peripheralImageRadius={50}
          centralImageRadius={50}
          focusElementStyling={{ border: '2px solid transparent' }}
          autoRotateTime={2}
          borderWidth={4}
          borderHexColor={'#1c364f'}
        />
        <div className="info-box-wrappertag">
          <h2>{info[focusElement].title}</h2> 
          <p>{info[focusElement].description}</p> 
          
          <a href={info[focusElement].link} className="read-more-button">
              Read More
            </a>

        </div>
      </div>
    </div>
          </div> */}
          <div className="scroll-sectionks">
          <div class="logoks" style={{width: "30.0027%"}}>


          <img src="/assets/images/saa.png" alt="salesforce"/></div>
<div class="content-containerks"><p class="contentks">Renowned as a Salesforce Crest (Gold) Consulting Partner, Saasverse is built on
an institution of trust, manifesting transparency in all our Salesforce services. We share
the same philosophy as Salesforce that businesses are the powerful catalyst for driving
positive change in the world, and we strive to reflect the same.&nbsp;</p><p class="contentks">Inspired by Salesforce’s trailblazing mindset, we continually propel the
boundaries to deliver cutting-edge solutions that keep you at the pinnacle of your industry.</p><ul class="list-contentks">
<li>
<a href="/salesforce-consulting-company">
Salesforce Consulting
</a>
</li>
<li>
<a href="/salesforce-custom-development-services">
Salesforce Development
</a>
</li>
<li>
<a href="/salesforce-isv-development-company">
Salesforce ISV Development
</a>
</li>
<li>
<a href="/salesforce-integration-services">
Salesforce Integration
</a>
</li>
<li>
<a href="/salesforce-appexchange-development-company">
Appexchange App Development
</a>
</li>
<li>
<a href="/salesforce-managed-services">
Salesforce Managed Services
</a>
</li></ul></div>
          </div>
          <div className="scroll-sectionks">
          <div class="logoks" style={{width: "20.2626%"}}>
<img src="/assets/images/aws-new.png" alt="aws"/>
</div>
<div class="content-containerks">
  <p class="contentks">At Saasverse, we’re not just leveraging Amazon Web Services (AWS), we’re creating a partnership that drives your business to new pinnacles. AWS’s vast array of services becomes the launching platform for your ideas under our guidance. We help you tap into the power of AWS’s infrastructure technologies, machine learning capabilities, data lakes, analytics, and more while ensuring security that's second to none.</p>
  <p class="contentks">Let's build your tomorrow with Saasverse's expertise and Amazon Web Services capabilities.</p><ul class="list-contentks">
<li>AWS Consulting</li>
<li>AWS Implementation</li>
<li>AWS Cloud Migration</li>
<li>AWS Data Warehouse</li>
<li>AWS Integration</li>
<li>Managed AWS Cloud Services</li>
<li>Amazon AppFlow</li></ul></div>
          </div>
        

          <div className="scroll-sectionks">
          <div class="logoks" style={{width: "30.2626%"}}>
<img src="/assets/images/mic-new.png" alt="microsoft"/>
</div>
<div class="content-containerks">
  <p class="contentks">Our team of consultants provides you with the guidelines to design, implement, and optimize Microsoft Azure solutions that satisfy your business needs. We accelerate your journey to the cloud, ensuring seamless migration with reduced technical debts, risks, and costs. Our methodologies are tested, and designed to deliver results that outperform your expectations.</p>
<p class="contentks">With Saasverse and Azure, you gain the agility to experiment, innovate rapidly, and stay ahead of the curve.</p>

</div>
          </div>


          <div className="scroll-sectionks">
          <div class="logoks" style={{width: "20.2626%"}}>
<img src="/assets/images/site-new.jpg" alt="sitecore"/>
</div>
<div class="content-containerks">
  <p class="contentks">Sitecore is a powerful digital experience platform (DXP) designed to help organizations deliver highly personalized and engaging customer experiences across multiple channels. At its core, Sitecore combines a robust content management system (CMS) with advanced tools for marketing automation, analytics, and e-commerce integration, making it a go-to solution for enterprises with complex content and marketing needs.</p>
<p class="contentks">One of Sitecore’s strengths is its ability to support headless CMS architecture, allowing developers to use APIs to deliver content to any device or platform. This flexibility makes it easier to adapt to emerging technologies and ensures a future-proof digital strategy.</p>
{/* <ul class="list-contentks">
<li><a>Nintex Mobile</a></li>




<li><a>Nintex Analytics</a></li>
<li>
<a>
Nintex Consulting
</a>
</li>
<li><a >Workflow for Office 365</a></li>

<li><a>Nintex Workflow Cloud</a></li>
<li><a>Support &amp; Maintenance</a></li>
</ul> */}
</div>
          </div>


          <div className="scroll-sectionks">
          <div class="logoks" style={{width: "30.2626%"}}>
<img src="/assets/images/nintex.png" alt="nintex"/>
</div>
<div class="content-containerks"><p class="contentks">Optimize your business processes with Saasverse’s Nintex consulting services.
We’re advocates of intelligent work – a mindset that leads us to support you in optimizing
your operations, standardizing your workflows, and automating business processes.</p><p class="contentks">With Saasverse and Nintex, you’re creating an ecosystem of continuous
improvement that adapts and grows with your business.</p><ul class="list-contentks">
<li>Nintex Mobile</li>
<li>Nintex Analytics</li>
<li>Nintex Consulting</li>
<li>Workflow for Office 365</li>
<li>Nintex Workflow Cloud</li>
<li>Support &amp; Maintenance</li>
</ul></div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default ScrollSection;