

export default [
  {
    id: 1,
    bgColor: "rgb(86 119 165)",
    // icon: <FontAwesomeIcon icon={faCameraRetro} size="3x" />,
    icon: <img src="/assets/images/serviceslogo/tt.png" alt="Icon Description"  />, 
    title: "Hire a Salesforce Developer",
    desc: "If you want to improve your Salesforce capabilities, you have come to the correct place. Our team of trained and exceptionally competent Salesforce developers can help you in creating customized solutions."
},
{
    id: 2,
    bgColor: "rgb(48 88 145)",
    icon: <img src="/assets/images/serviceslogo/salesforceconsultingnew.png" alt="Icon Description" />, 
    // icon: <FontAwesomeIcon icon={faGlassCheers} size="3x" />,
    title: "Salesforce Consulting",
    desc: "At Saasverse Technologies, we provide professional Salesforce consulting services to support organizations in making the best possible use of this dynamic cloud platform."
},
{
    id: 3,
    bgColor: "rgb(48 88 145)",
    icon: <img src="/assets/images/serviceslogo/Salesforce_Integration.png" alt="Icon Description"  />, 
    // icon: <FontAwesomeIcon icon={faDragon} size="3x" />,
    title: "Salesforce Integration Services",
    desc: "Our company specializes in providing companies of every sort Salesforce Integration Services. Our team of professionals has years of expertise connecting Salesforce with other platforms and programmes to boost productiveness."
},
{
    id: 4,
    bgColor: "#003e95",
    icon: <img src="/assets/images/serviceslogo/gg.png" alt="Icon Description"  />, 
    // icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
    title: "Amazon Web Services (AWS)",
    desc: "Looking to apply AWS for improving your company operations? Our professional Amazon Web Services (AWS) can be helpful."
},
{
    id: 5,
    bgColor: "rgb(48 88 145)",
    icon: <img src="/assets/images/serviceslogo/CRMnew.png" alt="Icon Description" />, 
    // icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
    title: "CRM Implementation",
    desc: "Salesforce is a dynamic and flexible platform that has changed how companies handle business in the current technological age. It offers a variety of tools and services that can assist businesses in more successfully"
},
{
    id: 6,
    bgColor: "rgb(86 119 165)",
    icon: <img src="/assets/images/serviceslogo/Salesforceappxchange.png" alt="Icon Description"/>, 
    // icon: <FontAwesomeIcon icon={faFootballBall} size="3x" />,
    title: "Salesforce AppExchange App Development",
    desc: "The largest business app store in the world, Salesforce AppExchange, offers more than 5000 solutions to expand and improve Salesforce CRM functionality"
  },
 
];
