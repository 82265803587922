import React, { useState, useEffect } from 'react';
import '../css/Testimonial.css'; // Assuming you're using the same CSS file or adapting it to React.

const testimonials = [
 
    {
        img: '/assets/images/testimonial/p1.jpg',
        name: 'Chris Crombie',
        text: 'Saasverse efforts have reorganized the client users, permissions, processes, and dashboards. Their communicative, flexible, and responsive approach resulted in a positive partnership. Ultimately, their Salesforce expertise complemented their technical expertise. '
    },
    {
        img: '/assets/images/testimonial/p3.jpg',
        name: 'Evelyn P',
        text: 'Saasverse team ability to quickly pivot their operations whenever the requirements and deadlines had to shift to accommodate the client. This allowed the company to continue with the project without wasting any time in regrouping efforts.'
    },
    {
        img: '/assets/images/testimonial/p4.jpg',
        name: 'Rohan Tyagi',
        text: 'Saasverse completed deliverables on time and provided detailed reports throughout the project. They had an efficient workflow, and transparent communication, which impressed the client.'
    },
 
];

const Testimonial = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isActive, setIsActive] = useState(0);
    const slideCount = testimonials.length;
    const testimSpeed = 4500; // Slide transition speed

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slideCount);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slideCount) % slideCount);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide();
        }, testimSpeed);

        return () => clearTimeout(timer);
    }, [currentSlide]);

    const handleDotClick = (index) => {
        setCurrentSlide(index);
    };

    return (
        <section className="testimcc" id="testimcc">
            <div className="wrapcc">
                <span id="right-arrowcc" className="arrowcc rightcc fa fa-chevron-right" onClick={nextSlide}></span>
                <span id="left-arrowcc" className="arrowcc leftcc fa fa-chevron-left" onClick={prevSlide}></span>

                <ul className="dotscc" id="testim-dotscc">
                    {testimonials.map((_, index) => (
                        <li
                            key={index}
                            className={`dotcc ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></li>
                    ))}
                </ul>

                <div className="contcc" id="testim-contentcc">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className={`slidecc ${currentSlide === index ? 'active' : 'inactive'}`}
                        >
                            {/* <div className="imgcc">
                                <img src={testimonial.img} alt={testimonial.name} /></div> */}
                            <p>{testimonial.text}</p>
                            <h2>{testimonial.name}</h2>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
