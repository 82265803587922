import {React,useEffect} from 'react'

import AnimatedTimeline from '../../../Home/NewComponent/AnimatedTimeline'
import NewHomeBanner from '../../../Home/Component/NewHomeBanner'
import NewBanner from '../../../Home/NewComponent/NewBanner'
import CertificateSlider from '../../../Home/Component/CertificateSlider'
import AppExchangeBanner2 from '../../Services/Salesforce/SalesforceAppExchange/Compo/AppExchangeBanner2'
import Trustedby2 from '../../../Home/Component/TrustedBy2'
import ExpertiseTimeline from './compo/ExpertiseTimeline'

export default function Expertise() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it runs only on component mount

  return (
    <>
     <NewBanner/>
     <ExpertiseTimeline/>
      {/* <AnimatedTimeline/> */}
      <CertificateSlider/>
      <AppExchangeBanner2/>
      <Trustedby2/>
    </>
  )
}
