import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import "./Carousel.css"; // Import the CSS file for styling

const Carousel = (props) => {
  const [activeSlide, setActiveSlide] = useState(props.activeSlide || 3);

  const next = () => {
    if (activeSlide < props.data.length - 1) {
      setActiveSlide(activeSlide + 1);
    }
  };

  const prev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const getStyles = (index) => {
    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: "translateX(-240px) translateZ(-400px) rotateY(35deg)",
        zIndex: 9
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: "translateX(240px) translateZ(-400px) rotateY(-35deg)",
        zIndex: 9
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 8
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 8
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: "translateX(-480px) translateZ(-500px) rotateY(35deg)",
        zIndex: 7
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: "translateX(480px) translateZ(-500px) rotateY(-35deg)",
        zIndex: 7
      };
    }
  };

  return (
    <div className="alllll">
<h2 className="service-headingll">Our Services</h2>
      {/* Carousel Content */}
      <div className="slideCnnll">
        {props.data.map((item, i) => (
          <React.Fragment key={item.id}>
            <div
              className="slidennll"
              style={{
                background: item.bgColor,
                boxShadow: `0 5px 20px ${item.bgColor}30`,
                ...getStyles(i)
              }}
            >
              <SliderContent {...item} />
            </div>
            <div
              className="reflectionnnll"
              style={{
                background: `linear-gradient(to bottom, ${item.bgColor}40, transparent)`,
                ...getStyles(i)
              }}
            />
          </React.Fragment>
        ))}
      </div>

      {/* Carousel Buttons */}
      <div className="btnsll">
        <FontAwesomeIcon
          className="btnll"
          onClick={prev}
          icon={faChevronLeft}
          color="black"
          size="2x"
        />
        <FontAwesomeIcon
          className="btnll"
          onClick={next}
          icon={faChevronRight}
          color="black"
          size="2x"
        />
      </div>
    </div>
  );
};

const SliderContent = (props) => {
  return (
    <div className="sliderContentnnll">
     <div className="sideimage"> {props.icon}</div>
      <h2>{props.title}</h2>
      <p>{props.desc}</p>
      
    </div>
  );
};

export default Carousel;
