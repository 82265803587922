import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import '../css/AboutSaasverse.css';

const AboutSaasverse = () => {
  const controls = useAnimation(); // Create animation controls

  useEffect(() => {
    const handleScroll = () => {
      const imageElement = document.querySelector('.about-saasverse-image');
      const imageTop = imageElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (imageTop < windowHeight - 100) {
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 1 },
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [controls]);

  // Text split for animation
  const aboutText = `Saasverse Technologies offers cutting-edge solutions to businesses
  looking to optimize their operations and processes. We customize
  our services, including Salesforce deployment and modification, app
  development, data integration, and automation, to match the unique
  demands of each customer. In order to guarantee that clients get
  the most out of their investment, we also offer continuous
  assistance and training. Our design team adheres to the most recent
  trends and best practices to develop visually appealing and
  approachable interfaces while maintaining brand coherence.
  Dedicated to quality, we go above and beyond for clients, helping
  companies prosper within the Salesforce ecosystem.`.split(" ");

  return (
    <div className="about-saasverse-container">
      <div className="about-saasverse-content">
        
        {/* Framer Motion applied to animate the image on scroll */}
        <motion.div
          className="about-saasverse-image"
          initial={{ opacity: 0, x: -100 }}
          animate={controls} // Link the controls to the animation
        >
          <img src="/assets/images/services/team.jpg" alt="Team Image" />
        </motion.div>

        <div className="about-saasverse-text">
          <p style={{ color: 'grey', marginBottom: '-10px', paddingBottom: '0px' }}>
            Welcome to Saasverse
          </p>
          <h2 className="centered-heading" style={{ textAlign: 'left', color: 'black' }}>
            About Us
          </h2>
          
          {/* Text Animation */}
          <div className="animated-text">
            {aboutText.map((word, i) => (
              <motion.span
                key={i}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: i * 0.05 }}
              >
                {word}{" "}
              </motion.span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSaasverse;
