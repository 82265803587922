
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ExpertiseTimeline.css'
export default function ExpertiseTimeline() {

  return (
   <>
   <h1 style={{textAlign:'center',marginTop:'40px',fontSize:'40px'}}>OUR EXPERTISE</h1>
   <ul class="timeline">
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Sales Cloud</h3>
      <p>At Saasverse Pvt Limited, we measure our success by the success of our clients. Discover how businesses like yours have achieved significant improvements in sales performance and operational efficiency through our Sales Cloud solutions.</p>
      <Link to='/expertise/salesforce-sales-cloud/' className="read-more-button" style={{color:'#003e95'}}>Read More</Link>
    </div>
  </li>
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Service Cloud</h3>
      
      <p>Salesforce Service Cloud is a customer service platform that helps businesses manage and resolve customer inquiries efficiently across multiple channels like email, chat, phone, and social media. It offers tools like case management, a knowledge base, AI-powered insights, and self-service portals to deliver personalized, proactive, and effective support.</p>
      <Link to='/expertise/salesforce-service-cloud/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>
  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Marketing Cloud</h3>
      <p>Salesforce Marketing Cloud is a comprehensive digital marketing platform that enables businesses to create personalized customer journeys. It supports multi-channel marketing through email, SMS, social media, and web, leveraging data-driven insights, AI-powered recommendations, and automation to engage customers at the right time with the right message.</p>
      <Link to='/expertise/salesforce-marketing-cloud/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>

  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Mulesoft Development</h3>
      <p>MuleSoft development focuses on building seamless integrations between systems, applications, and data sources using the Anypoint Platform. It enables businesses to create APIs and integrations that connect on-premise and cloud-based systems efficiently. MuleSoft developers specialize in designing API-led connectivity, leveraging reusable components, and ensuring scalable, secure, and real-time data exchange to streamline business operations.</p>
      <Link to='/expertise/mulesoft-development/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>

  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Health Cloud</h3>
      <p>Salesforce Health Cloud is a patient relationship management solution that integrates healthcare data from multiple systems into a single, comprehensive view. It empowers providers, payers, and pharmaceutical companies to deliver personalized care through tools for patient management, care coordination, and engagement. Health Cloud enables seamless communication, tracks patient journeys, and ensures compliance with industry standards to enhance overall healthcare outcomes.</p>
      <Link to='/expertise/health-cloud-services/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>

  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Expierience Cloud</h3>
      <p>Salesforce Experience Cloud (formerly Community Cloud) is a digital platform that allows organizations to create branded online experiences, such as portals, communities, forums, and customer support sites. It connects customers, partners, and employees by providing personalized access to information, tools, and collaborative spaces. Experience Cloud is designed to enhance engagement, streamline workflows, and drive business growth by integrating seamlessly with Salesforce data and systems.</p>
      <Link to='/expertise/experience-cloud/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>

  <li class="timeline-event">
    <label class="timeline-event-icon"></label>
    <div class="timeline-event-copy">
      <h3 class="timeline-event-thumbnail">Tableau CRM</h3>
      <p>Tableau CRM (formerly Einstein Analytics) is Salesforce's advanced analytics and business intelligence platform. It empowers organizations to explore data, uncover insights, and drive actionable decisions directly within the Salesforce ecosystem. </p>
      <Link to='/expertise/tableau-crm/' className="read-more-button" style={{color:'#003e95'}}>
Read More
</Link>
    </div>
  </li>

 
</ul>  
   </>
  );
}
 