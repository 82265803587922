import React, { useState, useEffect } from "react";
import "./NewBanner.css"; // Ensure your CSS styles are here
import { Link } from 'react-router-dom';
const images = [
  {
    name: "EXPERTISE",
    description:
      " Our expertise includes seamless integrations, data management, and tailored CRM solutions across industries.",
    url: "assets/images/banner/ex3.jpg",
  },
 

  // {
  //   name: "CROW",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/crow.jpg",
  // },
  // {
  //   name: "BUTTERFLY",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/butterfly1.jpeg",
  // },
  // // Add more image objects as needed

  // {
  //   name: "BUTTERFLY",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/butterfly2.jpg",
  // },
  // {
  //   name: "Eagel",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/eagel3.jpg",
  // },
  // {
  //   name: "HERON",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/heron.jpeg",
  // },
  // {
  //   name: "kingfirser",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis culpa similique consequuntur, reprehenderit dicta repudiandae.",
  //   url: "assets/images/banner/kingfirser2.jpeg",
  // },
];

const NewBanner = () => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    const timeAutoNext = 4000;
  
    const nextSlide = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    const prevSlide = () => {
      setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  
    useEffect(() => {
      const autoSlide = setTimeout(() => {
        nextSlide();
      }, timeAutoNext);
  
      return () => clearTimeout(autoSlide);
    }, [activeIndex]);
  
    const resetTimeAnimation = () => {
      const runningTime = document.querySelector(".timeRunning");
      runningTime.style.animation = "none";
      
      runningTime.style.animation = "runningTime 7s linear 1 forwards";
    };
  
    useEffect(() => {
      resetTimeAnimation();
    }, [activeIndex]);
  
    const upcomingIndex = (activeIndex + 1) % images.length;
  
    return (
      <div className="carousel">
        <div className="list">
          {images.map((image, index) => (
            <div
              key={index}
              className={`item ${index === activeIndex ? "active" : index === upcomingIndex ? "upcoming" : ""}`}
              style={{ backgroundImage: `url(${image.url})` }}
            >
              <div className="content">
                {index === activeIndex && (
                  <>
                    <div className="title">OUR</div>
                    <div className="name">{image.name}</div>
                    <div className="des">{image.description}</div>
                    <div className="btn">
                      <button > <Link to='/contact-us'  style={{ textDecoration: 'none', color: 'white' }}> Get a Quote</Link></button>
                      {/* <button>Subscribe</button> */}
                     
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
  
        {/* Arrows */}
        {/* <div className="arrows">
          <button className="prev" onClick={prevSlide}>
            &lt;
          </button>
          <button className="next" onClick={nextSlide}>
            &gt;
          </button>
        </div> */}
  
        {/* Time running animation */}
        <div className="timeRunning"></div>
      </div>
    );
  };
  
  export default NewBanner;
  


