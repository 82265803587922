import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import "./OurPartners.css"

function OurPartners() {
    const row1 = [
        "/assets/images/ourclients/Databricks.png",
        "/assets/images/ourclients/Doordash.png",
        "/assets/images/ourclients/Elkay.png",
        "/assets/images/ourclients/Five9.png",
        "/assets/images/ourclients/IngersolRand.png",
        "/assets/images/ourclients/Lafarge.png",
      ];
    
      const row2 = [
        "/assets/images/ourclients/Lkpackaging.png",
        "/assets/images/ourclients/Marlab.png",
        "/assets/images/ourclients/SYSCO.png",
        "/assets/images/ourclients/Telstra.png",
        "/assets/images/ourclients/XPO.png",
        "/assets/images/ourclients/origin.png",
        "/assets/images/ourclients/SpringEducationGroup.png",
      ];
    const [lettersRef, setlettersRef] = useArrayRef();
    const triggerRef = useRef(null)


    function useArrayRef() {
        const lettersRef = useRef([]);
        lettersRef.current = [];
        return [lettersRef, (ref) => ref && lettersRef.current.push(ref)];
    }

    gsap.registerPlugin(ScrollTrigger);
    const text = "Trusted by global business and respected partners"

    useEffect(() => {
        const anim = gsap.to(
            lettersRef.current,
            {
                scrollTrigger: {
                    trigger: triggerRef.current,
                    scrub: true,
                    start: "top center",
                    end: "bottom 85%"

                },
                color: "white",
                duration: 5,
                stagger: 1,
            }
        );
        return (() => {
            anim.kill()
        })
    }, []);

    return (
        <>
            <div className="spacing-small"></div>
            <div className="reveal">
                <div ref={triggerRef}>
                    {text.split("").map((letter, index) => (
                        <span className="reveal-text" key={index} ref={setlettersRef} style={{color:'black'}}>
                            {letter}
                        </span>
                    ))}
                </div>
              
            </div>
            <div className="app-container">
      <div className="wrapper">
        {/* <div className="text">With Great Outcomes.</div> */}
        {/* <div className="note">Our customers have gotten offers from awesome companies.</div> */}
        <div className="marquee">
          <div className="marquee-group">
            {row1.map((el, index) => (
              <div className="image-group" key={index}>
                <img src={el} alt={`Image ${index + 1}`} className="image" />
              </div>
            ))}
          </div>
          <div className="marquee-group">
            {row1.map((el, index) => (
              <div className="image-group" key={index}>
                <img src={el} alt={`Image ${index + 1}`} className="image" />
              </div>
            ))}
          </div>
        </div>
        <div className="marquee">
          <div className="marquee-group2">
            {row2.map((el, index) => (
              <div className="image-group" key={index}>
                <img src={el} alt={`Image ${index + 1}`} className="image" />
              </div>
            ))}
          </div>
          <div className="marquee-group2">
            {row2.map((el, index) => (
              <div className="image-group" key={index}>
                <img src={el} alt={`Image ${index + 1}`} className="image" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
        </>
    )
}

export default OurPartners