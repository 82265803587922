import React, { useEffect } from 'react'; // Import useEffect
import HeaderSlider from './Component/HeaderSlider';
import AboutSaasverse from './Component/AboutSaasverse';
import OurServices from './Component/OurServices';
import TrustedPartner from './Component/TrustedPartner';
import Testimonial from './Component/Testimonial';
import Banner from './Component/Banner';
import Faq from '../Pages/Faq/Faq';
import Trustedby2 from './Component/TrustedBy2';
import CertificateSlider from './Component/CertificateSlider';
import HomeBann from './Component/HomeBann';
import Partner from './Component/Partner';
import ContactForm from '../Pages/Company/Contact Us/contactcompo/ContactForm';
import Carousel from './NewComponent/Carousel';
import carouselData from './NewComponent/carouselData';
import OurPartners from './NewComponent/OurPartners';
import ScrollSection from './NewComponent/ScrollSection';
import HomeEntry from './Component/HomeEntry';
import NewHomeBanner from './Component/NewHomeBanner';
import SalesforceExpertiseHome from './NewComponent/SalesforceExpertiseHome';


export default function Home() {
  useEffect(() => {
    // Scroll to top when the Home component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it runs only on component mount

  return (
    <>

      {/* <HomeEntry/> */}
      {/* <HeaderSlider/> */}
      {/* <HomeBann/> */}
      {/* <NewHomeBanner/> */}
      <HeaderSlider />
      <AboutSaasverse />
      {/* <OurServices/> */}
      <ScrollSection />
      <SalesforceExpertiseHome/>
      <CertificateSlider />
      <Partner />
      <Carousel data={carouselData} />
      {/* <OurServices/> */}
      {/* <TrustedPartner/> */}
      <OurPartners />
      {/* <Trustedby2/> */}
      <Testimonial />
      <Banner />
      <Faq />
      <ContactForm />
    </>
  );
}
