import React, { useState } from 'react';
import './Batch2.css';

const CourseItem = ({ course, toggleBatches }) => (
  <li className="course-item">
    <div className="course-info">
      <div className="course-header">
        <span className="course-title">{course.course}</span>
      </div>
      <div className="course-description">{course.description}</div>
      <button
        className="toggle-button"
        onClick={() => toggleBatches(course.id)}
      >
        Batch Details {course.showBatches ? '-' : '+'}
      </button>
      {course.showBatches && (
        <ul className="batch-sublist">
          {course.batches.map((batch) => (
            <li key={batch.id} className="batch-item">
              <div className="batch-info">
                <span>{batch.name} - {batch.date} at {batch.time}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  </li>
);

const Batch2 = () => {
  const [selectedBatchType, setSelectedBatchType] = useState('ongoing');

  const ongoingBatches = [
    {
      id: 1,
      course: 'Salesforce Admin & Development',
      description: 'Begin with Salesforce administration essentials, including user management, security, and data handling. Progress to advanced features like workflow automation, reporting, and dashboards. For development, start with basics like Apex programming, Visualforce, and Lightning components, then master advanced skills in integration, custom app development, and performance optimization.',
      showBatches: false,
      batches: [
        { id: 1, name: 'Batch 1', date: '2024-11-15', time: '10:00 AM' },
        { id: 2, name: 'Batch 2', date: '2024-10-22', time: '12:00 PM' },
        { id: 3, name: 'Batch 3', date: '2024-11-01', time: '3:00 PM' },
      ],
    },
    {
      id: 2,
      course: 'Salesforce Admin',
      description: 'Start with the basics of Salesforce administration, focusing on user management, security settings, and data handling. Progress to advanced features like workflow automation, reporting, and dashboards to effectively manage and optimize Salesforce environments.',
      showBatches: false,
      batches: [
        { id: 4, name: ' Batch 1', date: '2024-11-20', time: '2:00 PM' },
      ],
    },
    {
      id: 3,
      course: 'Salesforce CPQ',
      description: ' Start with the basics of Salesforce CPQ by learning how to configure products, pricing, and quoting. Progress to advanced features such as discount management, product rules, and guided selling to create accurate and efficient quotes for customers.',
      showBatches: false,
      batches: [
        { id: 7, name: ' Batch 1', date: '2024-11-05', time: '11:00 AM' },
        { id: 8, name: ' Batch 2', date: '2024-11-12', time: '1:00 PM' },
      ],
    },
  ];

  const upcomingBatches = [
    {
      id: 4,
      course: 'Salesforce Admin & Development',
      description: 'Begin with Salesforce administration essentials, including user management, security, and data handling. Progress to advanced features like workflow automation, reporting, and dashboards. For development, start with basics like Apex programming, Visualforce, and Lightning components, then master advanced skills in integration, custom app development, and performance optimization.',
      showBatches: false,
      batches: [
        { id: 5, name: 'Batch 1', date: '2024-12-5', time: '10:00 AM' },
      ],
    },
    {
      id: 5,
      course: 'Salesforce Admin',
      description: 'Start with the basics of Salesforce administration, focusing on user management, security settings, and data handling. Progress to advanced features like workflow automation, reporting, and dashboards to effectively manage and optimize Salesforce environments.',
      showBatches: false,
      batches: [
        { id: 6, name: 'Batch 1', date: '2024-12-10', time: '11:00 AM' },
      ],
    },
    {
      id: 6,
      course: 'Salesforce CPQ',
      description: ' Start with the basics of Salesforce CPQ by learning how to configure products, pricing, and quoting. Progress to advanced features such as discount management, product rules, and guided selling to create accurate and efficient quotes for customers.',
      showBatches: false,
      batches: [
        { id: 9, name: 'Batch 1', date: '2024-12-2', time: '9:00 AM' },
        { id: 10, name: 'Batch 2', date: '2024-12-10', time: '10:30 AM' },
      ],
    },
  ];

  const [courses, setCourses] = useState(selectedBatchType === 'ongoing' ? ongoingBatches : upcomingBatches);

  const toggleBatches = (courseId) => {
    const updatedCourses = courses.map(course => {
      if (course.id === courseId) {
        return { ...course, showBatches: !course.showBatches };
      }
      return course;
    });
    setCourses(updatedCourses);
  };

  const handleBatchTypeChange = (type) => {
    setSelectedBatchType(type);
    setCourses(type === 'ongoing' ? ongoingBatches : upcomingBatches);
  };

  return (
    <div className="batch-container">
      <div className="batch-buttons">
        <button
          className={selectedBatchType === 'ongoing' ? 'active' : ''}
          onClick={() => handleBatchTypeChange('ongoing')}
        >
          Ongoing Batches
        </button>
        <button
          className={selectedBatchType === 'upcoming' ? 'active' : ''}
          onClick={() => handleBatchTypeChange('upcoming')}
        >
          Upcoming Batches
        </button>
      </div>
      <ul className="batch-list">
        {courses.map((course) => (
          <CourseItem key={course.id} course={course} toggleBatches={toggleBatches} />
        ))}
      </ul>
    </div>
  );
};

export default Batch2;
